body {
  background-color: #F5F6F7;
  margin: 0 auto;
  max-width: 450px;
  min-width: 320px;
}

p,
div {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.alert-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: #000;
  opacity: 0.3;
  left: 0;
  top: 0
}

.alert-dialog {
  text-align: center;
  position: fixed;
  width: 90%;
  margin-left: 5%;
  left: 0;
  top: 30%;
  z-index: 30;
  background-color: #fff;
  color: #000;
  border-radius: 3px;
}

.alert-dialog div {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin: 0 10px;
}

.alert-msg {
  padding-top: 10px;
}

.alert-confirm {
  position: relative;
  border-top: 1px solid #DDD;
}

.alert-confirm p {
  position: absolute;
  width: 50%;
}

.alert-yes {
  left: 0;
}

.alert-cancel {
  right: 0;
}

.left-menu-container {
  position: fixed;
  z-index: 10;
  width: 84%;
  height: 100%;
  background-color: #42485C;
  left: 0;
  top: 0;
  opacity: 0.96;
  display: none;
}

.menu-user-area {
  margin-top: 60px;
  text-align: center;
}

.menu-avatar {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.menu-username {
  margin-top: 10px;
  font-size: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
}

.menu-item-container {
  margin-top: 25px;
  color: #fff;
}

.menu-item {
  position: relative;
  padding: 15px 0;
  padding-left: 48px;
  font-size: 14px;
}

.menu-item-icon {
  position: absolute;
  left: 24px;
  top: 16px;
  width: 15px;
}

.menu-right-side {
  position: fixed;
  z-index: 10;
  width: 16%;
  height: 100%;
  background-color: #000;
  left: 84%;
  top: 0;
  opacity: 0.3;
}

.top-container {
  width: 100%;
  color: #fff;
  background-color: #06C1AE;
}

.tip-container {
  position: relative;
  width: 100%;
  height: 39px;
  line-height: 39px;
  text-align: left;
  font-size: 13px;
  background-color: #06C1AE;
  opacity: 0.6;
  color: #fff;
}

.tip-content {
  margin-left: 10px;
}

.tip-forward {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 5px;
}

.header {
  background-color: #06C1AE;
  width: 100%;
  position: relative;
  height: 44px;
  text-align: center;
}

.header-icon {
  height: 18px;
}

.menu {
  position: absolute;
  left: 13px;
  top: 13px;
}

.help {
  position: absolute;
  right: 13px;
  top: 13px;
}

.title-current-time {
  text-align: center;
  font-size: 16px;
  position: absolute;
  left: 20%;
  top: 15px;
  width: 60%;
}

.content-container {
  margin-top: 10px;
  color: #4A4A4A;
}

.content-detail-item {
  margin-top: 10px;
  background-color: #FFF;
  font-size: 14px;
}

.content-title {
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #D7D7D7;
}

.content-detail-row div {
  display: inline-block;
  text-align: center;
  width: 49%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.content-money {
  color: #06C1AE;
}

.content-detail-row div:first-child {
  border-right: 1px solid #D7D7D7;
}

.content-detail-row div p {
  line-height: 22px;
}

.content-money span {
  font-size: 20px;
}

.content-money {
  margin-top: 4px;
}

.op-container {
  margin-top: 9px;
  color: #4A4A4A;
  width: 100%;
}

.op-item-area {
  position: relative;
  width: 100%;
  margin-top: 1px;
  background-color: #fff;
  height: 49px;
}

.op-item-area p {
  position: absolute;
  left: 35px;
  top: 15px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
}

.op-item-area div {
  position: absolute;
  color: #D8D8D8;
  right: 13px;
  top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.history-icon {
  width: 15px;
  margin-top: 17px;
  margin-left: 10px;
}

.account-icon {
  width: 16px;
  margin-top: 18px;
  margin-left: 9px;
}

@media screen and (min-width: 360px) and (max-width: 399px) {
  .tip-container {
    height: 46px;
    line-height: 46px;
    font-size: 15px;
  }
  .tip-content {
    margin-left: 12px;
  }
  .tip-forward {
    top: 18px;
    right: 22px;
    width: 5px;
  }
  .header {
    height: 52px;
  }
  .header-icon {
    height: 21px;
  }
  .alert-dialog div {
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    margin: 0 12px;
  }
  .alert-msg {
    padding-top: 12px;
  }
  .menu {
    left: 15px;
    top: 15px;
  }
  .menu-user-area {
    margin-top: 72px;
  }
  .menu-avatar {
    width: 72px;
    height: 72px;
    border-radius: 72px;
  }
  .menu-username {
    margin-top: 12px;
    font-size: 18px;
    height: 18px;
    line-height: 18px;
  }
  .menu-item-container {
    margin-top: 30px;
  }
  .menu-item {
    font-size: 16px;
    padding: 18px 0;
    padding-left: 58px;
  }
  .menu-item-icon {
    left: 28px;
    top: 18px;
    width: 18px;
  }
  .help {
    right: 15px;
    top: 15px;
  }
  .title-current-time {
    top: 18px;
    font-size: 18px;
  }
  .content-container {
    margin-top: 12px;
  }
  .content-detail-item {
    margin-top: 12px;
    font-size: 16px;
  }
  .content-title {
    padding-left: 12px;
    height: 36px;
    line-height: 36px;
  }
  .content-detail-row div {
    height: 48px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .content-detail-row div p {
    line-height: 26px;
  }
  .content-money span {
    font-size: 24px;
  }
  .op-container {
    margin-top: 11px;
  }
  .op-item-area {
    margin-top: 1px;
    height: 59px;
  }
  .op-item-area p {
    left: 42px;
    top: 18px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
  }
  .op-item-area div {
    right: 15px;
    top: 24px;
    font-size: 16px;
  }
  .history-icon {
    width: 18px;
    margin-top: 20px;
    margin-left: 12px;
  }
  .account-icon {
    width: 19px;
    margin-top: 22px;
    margin-left: 11px;
  }
}

@media screen and (min-width: 400px) {
  .tip-container {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
  .tip-content {
    margin-left: 13px;
  }
  .tip-forward {
    top: 20px;
    right: 24px;
    width: 7px;
  }
  .header {
    height: 57px;
  }
  .header-icon {
    height: 23px;
  }
  .alert-dialog div {
    font-size: 18px;
    height: 53px;
    line-height: 53px;
    margin: 0 13px;
  }
  .alert-msg {
    padding-top: 13px;
  }
  .menu {
    left: 17px;
    top: 17px;
  }
  .menu-user-area {
    margin-top: 80px;
  }
  .menu-avatar {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
  .menu-username {
    margin-top: 13px;
    font-size: 20px;
    height: 20px;
    line-height: 20px;
  }
  .menu-item-container {
    margin-top: 34px;
  }
  .menu-item {
    font-size: 18px;
    padding: 20px 0;
    padding-left: 64px;
  }
  .menu-item-icon {
    left: 32px;
    top: 19px;
    width: 20px;
  }
  .help {
    right: 17px;
    top: 17px;
  }
  .title-current-time {
    top: 20px;
    font-size: 20px;
  }
  .content-container {
    margin-top: 13px;
  }
  .content-detail-item {
    margin-top: 13px;
    font-size: 18px;
  }
  .content-title {
    padding-left: 13px;
    height: 40px;
    line-height: 40px;
  }
  .content-detail-row div {
    height: 52px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content-detail-row div p {
    line-height: 30px;
  }
  .content-money span {
    font-size: 26px;
  }
  .op-container {
    margin-top: 12px;
  }
  .op-item-area {
    margin-top: 2px;
    height: 66px;
  }
  .op-item-area p {
    left: 46px;
    top: 20px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
  }
  .op-item-area div {
    right: 17px;
    top: 26px;
    font-size: 18px;
  }
  .history-icon {
    width: 20px;
    margin-top: 22px;
    margin-left: 13px;
  }
  .account-icon {
    width: 21px;
    margin-top: 22px;
    margin-left: 12px;
  }
}
